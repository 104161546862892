import React, {useEffect, useState} from 'react';
import {Route} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';

import Loader from './components/loader'
import Home from './pages/home'
import Free from './pages/free'
import Form from './pages/form'

import './App.scss';

const routes = [
  {path: '/', name: 'Home', Component: Home},
  {path: '/free', name: 'Free', Component: Free},
  {path: '/form', name: 'Form', Component: Form},
]

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  })

  return (
    <>
      {isLoading === true ?
        <Loader />  :
      <div>
        {routes.map(({name, path, Component}) => (
            <Route key={name} path={path} exact>
                {({match}) => (
                  <CSSTransition
                  in={match != null}
                  timeout={1200}
                  classNames='page'
                  unmountOnExit
                  >
                    <div className="page">
                      <Component /> 
                    </div>
                  </CSSTransition>
                )}
            </Route>
        ))}
      </div>
      }
    </>
  );
}

export default App;